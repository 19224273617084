// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import * as Sentry from "@sentry/browser";
import { Debug } from "@sentry/integrations";
import Cookie from "js-cookie";

const FILTERED_EVENTS = [
  "viewport-mercator-project: assertion failed.",
  "InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.",
  "QuotaExceededError",
];

export function configureSentry(
  release: string = "kinderkiez-app@" + process.env.COMMIT,
  environment: string = process.env.ENVIRONMENT
) {
  const sentryOptions: Sentry.BrowserOptions = {
    dsn: process.env.SENTRY_DSN,
    release,
    environment,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    beforeSend(event, hint) {
      const originalError = hint.originalException as Error;

      if (
        (originalError &&
          originalError.message &&
          FILTERED_EVENTS.includes(originalError.message)) ||
        (originalError && FILTERED_EVENTS.includes(originalError.toString())) ||
        window.navigator.userAgent.includes("Prerender")
      ) {
        return null;
      }

      return event;
    },
  };

  // When we're developing locally
  if (process.env.ENVIRONMENT !== "production") {
    const sentryTestkit = require("sentry-testkit");
    const { sentryTransport } = sentryTestkit();

    // Don't actually send the errors to Sentry
    sentryOptions.transport = sentryTransport;

    // Instead, dump the errors to the console
    //@ts-ignore
    sentryOptions.integrations = [
      new Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ];
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    captureException: (err: any, ctx: any) => {
      Sentry.configureScope((scope) => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra("statusCode", err.statusCode);
        }

        // Firebase codes
        if (err.code) {
          scope.setExtra("code", err.code);
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx;

          if (res && res.statusCode) {
            scope.setExtra("statusCode", res.statusCode);
          }

          if (typeof window !== "undefined") {
            scope.setTag("ssr", "false");
            scope.setExtra("query", query);
            scope.setExtra("pathname", pathname);

            // On client-side we use js-cookie package to fetch it
            const sessionId = Cookie.get("sid");
            if (sessionId) {
              scope.setUser({ id: sessionId });
            }
          } else {
            scope.setTag("ssr", "true");
            scope.setExtra("url", req.url);
            scope.setExtra("method", req.method);
            scope.setExtra("headers", req.headers);
            scope.setExtra("params", req.params);
            scope.setExtra("query", req.query);

            // On server-side we take session cookie directly from request
            if (req.cookies.sid) {
              scope.setUser({ id: req.cookies.sid });
            }
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach((key) =>
              scope.setExtra(key, errorInfo[key])
            );
          }
        }
      });

      return Sentry.captureException(err);
    },
  };
}
