import App from "next/app";
import React from "react";
import { Translation } from "react-i18next";
import { ErrorMessage } from "../components/ErrorMessage";
import "../global.css";
import { configureSentry } from "../helpers/sentry";
import { appWithTranslation } from "../helpers/translation";
import "../helpers/polyfills.ts";

const { Sentry, captureException } = configureSentry();

interface State {
  hasError: boolean;
  errorEventId: string;
}

class CustomApp extends App<{}, {}, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorEventId: undefined };
  }

  static async getInitialProps({ Component, ctx }) {
    try {
      let pageProps = {};

      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }

      return { pageProps };
    } catch (error) {
      const errorEventId = captureException(error, ctx);
      return {
        hasError: true,
        errorEventId,
        pageProps: {}
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });

    this.setState({ errorEventId });
  }

  render() {
    return this.state.hasError ? (
      <ErrorMessage>
        <Translation
          children={(t, { i18n }) => (
            <>
              {t("error_message")}{" "}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();

                  Sentry.showReportDialog({
                    eventId: this.state.errorEventId,
                    lang: i18n.language,
                    title: t("error_report-title"),
                    subtitle: t("error_report-subtitle"),
                    subtitle2: "",
                    successMessage: t("error_report-success")
                  });
                }}
              >
                {t("error_report")}
              </a>
            </>
          )}
        />
      </ErrorMessage>
    ) : (
      // Render the normal Next.js page
      super.render()
    );
  }
}

export default appWithTranslation(CustomApp);
